import { SvgIcon } from "@mui/material";
import { ActionItemIcon } from "../../types/actionItem";
import { FC } from "react";

export const AddActionIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="58.335"
                height="58.335"
                viewBox="0 0 58.335 58.335"
            >
                <g data-name="Icon feather-plus-circle">
                    <path
                        data-name="Path 30"
                        d="M57.335 30.167A27.167 27.167 0 1 1 30.167 3a27.167 27.167 0 0 1 27.168 27.167z"
                        style={{
                            fill: "none",
                            stroke: "#b7b5b5",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "4px",
                        }}
                        transform="translate(-1 -1)"
                    />
                    <path
                        data-name="Path 31"
                        d="M18 12v21.734"
                        transform="translate(11.167 6.3)"
                        style={{
                            fill: "none",
                            stroke: "#b7b5b5",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "4px",
                        }}
                    />
                    <path
                        data-name="Path 32"
                        d="M12 18h21.734"
                        transform="translate(6.3 11.167)"
                        style={{
                            fill: "none",
                            stroke: "#b7b5b5",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "4px",
                        }}
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const AwesomeCheckIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34.059"
                height="25.398"
                viewBox="0 0 34.059 25.398"
            >
                <path
                    data-name="Icon awesome-check"
                    d="M11.568 29.477.5 18.408A1.7 1.7 0 0 1 .5 16l2.408-2.408a1.7 1.7 0 0 1 2.408 0l7.457 7.457L28.744 5.076a1.7 1.7 0 0 1 2.408 0l2.408 2.408a1.7 1.7 0 0 1 0 2.408L13.976 29.477a1.7 1.7 0 0 1-2.408 0z"
                    transform="translate(0 -4.577)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const CalendarMonthIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={color}
            >
                <path
                    d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Zm280 240q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z"
                />
            </svg>
        </SvgIcon>
    );
};

export const CancelIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
            >
                <path
                    fill="#be2621"
                    d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
                />
            </svg>
        </SvgIcon>
    );
};

export const ClipboardListIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="21"
                viewBox="0 0 15 21"
            >
                <path
                    data-name="Icon awesome-clipboard-list"
                    d="M13.125 2.625H10A2.567 2.567 0 0 0 7.5 0 2.567 2.567 0 0 0 5 2.625H1.875A1.924 1.924 0 0 0 0 4.594v14.437A1.924 1.924 0 0 0 1.875 21h11.25A1.924 1.924 0 0 0 15 19.031V4.594a1.924 1.924 0 0 0-1.875-1.969zM3.75 17.391a.986.986 0 0 1 0-1.969.986.986 0 0 1 0 1.969zm0-3.937a.986.986 0 0 1 0-1.969.986.986 0 0 1 0 1.969zm0-3.937a.986.986 0 0 1 0-1.969.986.986 0 0 1 0 1.969zM7.5 1.641a.959.959 0 0 1 .938.984.939.939 0 1 1-1.875 0 .959.959 0 0 1 .937-.984zm5 15.094a.322.322 0 0 1-.312.328H6.563a.322.322 0 0 1-.313-.328v-.656a.322.322 0 0 1 .313-.328h5.625a.322.322 0 0 1 .312.328zm0-3.937a.322.322 0 0 1-.312.328H6.563a.322.322 0 0 1-.313-.326v-.656a.322.322 0 0 1 .313-.328h5.625a.322.322 0 0 1 .312.328zm0-3.937a.322.322 0 0 1-.312.328H6.563a.322.322 0 0 1-.313-.328V8.2a.322.322 0 0 1 .313-.328h5.625a.322.322 0 0 1 .312.328z"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const CarWashIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
            >
                <path
                    data-name="Icon map-car-wash"
                    d="M25.8 3.993V1.44H4.2v2.553H0V31.44h2.4V6.546h1.8V9.1h21.6V6.546h1.8V31.44H30V3.993zm-.569 14.681-3.915-7.1a1.084 1.084 0 0 0-.868-.559H9.551a1.083 1.083 0 0 0-.867.559l-3.914 7.1a1.23 1.23 0 0 0-1.17 1.277v6.383a1.23 1.23 0 0 0 1.169 1.276H6v1.915a1.8 1.8 0 0 0 1.692 1.915h.585a1.988 1.988 0 0 0 1.923-1.915V27.61h9.6v1.915a1.987 1.987 0 0 0 1.923 1.915h.585A1.8 1.8 0 0 0 24 29.525V27.61h1.231a1.23 1.23 0 0 0 1.169-1.276v-6.383a1.23 1.23 0 0 0-1.169-1.277zM6.815 23.78a1.6 1.6 0 0 1 0-3.191 1.6 1.6 0 0 1 0 3.191zm.877-5.106 2.662-5.174a1.033 1.033 0 0 1 .846-.571h7.6a1.031 1.031 0 0 1 .846.571l2.662 5.174H7.692zm15.493 5.106a1.6 1.6 0 1 1 1.462-1.6 1.533 1.533 0 0 1-1.462 1.6z"
                    transform="translate(0 -1.44)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const ClockIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
            >
                <path
                    data-name="Icon awesome-clock"
                    d="M10.063.563a9.5 9.5 0 1 0 9.5 9.5 9.5 9.5 0 0 0-9.5-9.5zm2.187 13.411-3.379-2.456a.463.463 0 0 1-.188-.372V4.7a.461.461 0 0 1 .46-.46h1.839a.461.461 0 0 1 .46.46v5.274l2.432 1.77a.459.459 0 0 1 .1.644l-1.08 1.486a.463.463 0 0 1-.644.1z"
                    transform="translate(-.563 -.563)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const DeleteIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.542"
                height="19.982"
                viewBox="0 0 15.542 19.982"
            >
                <path
                    data-name="Icon material-delete-forever"
                    d="M8.61 22.262a2.227 2.227 0 0 0 2.22 2.22h8.881a2.227 2.227 0 0 0 2.22-2.22V8.94H8.61zm2.731-7.9 1.565-1.565 2.365 2.353 2.353-2.353 1.565 1.565-2.353 2.353 2.353 2.353-1.565 1.562-2.353-2.353-2.354 2.353-1.565-1.565 2.353-2.353zm7.815-8.752-1.11-1.11H12.5l-1.11 1.11H7.5v2.22h15.542V5.61z"
                    transform="translate(-7.5 -4.5)"
                    fill="#b7b5b5"
                />
            </svg>
        </SvgIcon>
    );
};

export const IndeterminateIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#b7b5b5">
                <path
                    d="M280-440h400v-80H280v80Zm-80 320q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
            </svg>
        </SvgIcon>
    )
}

export const SearchIcon = () => {
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368">
                <path
                    d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
            </svg>
        </SvgIcon>
    )
}

export const DocumentIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.015"
                height="28.619"
                viewBox="0 0 22.015 28.619"
            >
                <path
                    data-name="Icon ionic-md-document"
                    d="M19.959 3.375H9.5a2.752 2.752 0 0 0-2.75 2.752v23.115a2.752 2.752 0 0 0 2.75 2.752h16.513a2.752 2.752 0 0 0 2.752-2.752V12.181zm-1.1 9.907V5.576l7.705 7.705z"
                    transform="translate(-6.75 -3.375)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const FuelStationIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.622"
                height="27.951"
                viewBox="0 0 25.622 27.951"
            >
                <path
                    data-name="Icon material-local-gas-station"
                    d="m30.488 11.068.016-.016-5.777-5.776-1.646 1.646 3.277 3.278a3.876 3.876 0 0 0 1.382 7.5 3.969 3.969 0 0 0 1.553-.326v11.2a1.553 1.553 0 0 1-3.106 0v-6.993a3.115 3.115 0 0 0-3.106-3.106h-1.553V7.606A3.115 3.115 0 0 0 18.423 4.5H9.106A3.115 3.115 0 0 0 6 7.606v24.845h15.528V20.8h2.329v7.764a3.882 3.882 0 0 0 7.764 0V13.817a3.885 3.885 0 0 0-1.133-2.749zm-12.065 4.3H9.106V7.606h9.317zm9.317 0a1.553 1.553 0 1 1 1.553-1.553 1.557 1.557 0 0 1-1.553 1.555z"
                    transform="translate(-6 -4.5)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const GpsIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.728"
                height="20.728"
                viewBox="0 0 20.728 20.728"
            >
                <path
                    data-name="Icon material-gps-fixed"
                    d="M11.864 8.1a3.769 3.769 0 1 0 3.769 3.769A3.768 3.768 0 0 0 11.864 8.1zm8.423 2.826a8.474 8.474 0 0 0-7.481-7.481V1.5h-1.884v1.941a8.474 8.474 0 0 0-7.481 7.481H1.5v1.884h1.941a8.474 8.474 0 0 0 7.481 7.481v1.941h1.884v-1.941a8.474 8.474 0 0 0 7.481-7.481h1.941v-1.884zm-8.423 7.537a6.6 6.6 0 1 1 6.6-6.6 6.59 6.59 0 0 1-6.6 6.596z"
                    transform="translate(-1.5 -1.5)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const LeafIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.605"
                height="19.019"
                viewBox="0 0 20.605 19.019"
            >
                <path
                    data-name="Icon ionic-ios-leaf"
                    d="M23.48 20.087a10.02 10.02 0 0 1-2.759-.58c.773-3.665-1.109-7.484-3.779-9.688-3.556-2.932-8.96-.8-13.17-5.26-.981-1.04-.412 11.665 4.858 16.479a9.265 9.265 0 0 0 9.634 1.862 5.515 5.515 0 0 0 1.917-1.679 14.536 14.536 0 0 0 3.071.7c.723.093 1.09-1.7.228-1.834zm-4.547.367c-3.849-1.154-7.2-4.012-9.366-6.251a.2.2 0 0 1 .272-.3 30.121 30.121 0 0 0 9.574 5.344 1.847 1.847 0 0 1-.48 1.207z"
                    transform="translate(-3.376 -4.499)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const PersonIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
            >
                <path
                    data-name="Icon ionic-md-person"
                    d="M13.5 13.5A4.5 4.5 0 1 0 9 9a4.513 4.513 0 0 0 4.5 4.5zm0 2.25c-2.981 0-9 1.519-9 4.5v2.25h18v-2.25c0-2.981-6.019-4.5-9-4.5z"
                    transform="translate(-4.5 -4.5)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const QuickReferenceIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill={color}
            >
                <path
                    d="M660-160h40v-160h-40v160Zm20-200q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM200-800v640-640 200-200Zm80 400h147q11-23 25.5-43t32.5-37H280v80Zm0 160h123q-3-20-3-40t3-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v92q-19-6-39-9t-41-3v-40H480v-200H200v640h227q11 23 25.5 43T485-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Z"
                />
            </svg>
        </SvgIcon>
    );
};

export const SmartphoneIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.859"
                height="20.386"
                viewBox="0 0 12.859 20.386"
            >
                <g data-name="Icon feather-smartphone">
                    <path
                        data-name="Path 38"
                        d="M9.051 3h7.757a1.713 1.713 0 0 1 1.551 1.839v14.708a1.713 1.713 0 0 1-1.551 1.839H9.051A1.713 1.713 0 0 1 7.5 19.547V4.839A1.713 1.713 0 0 1 9.051 3z"
                        transform="translate(-6.5 -2)"
                        style={{
                            fill: "none",
                            stroke: color,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                    <path
                        data-name="Path 39"
                        d="M18 27h0"
                        transform="translate(-11.57 -11.291)"
                        style={{
                            fill: "none",
                            stroke: color,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: "2px",
                        }}
                    />
                </g>
            </svg>
        </SvgIcon>
    );
};

export const SmileIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
            >
                <path
                    data-name="Icon awesome-smile"
                    d="M9.5.563a9.5 9.5 0 1 0 9.5 9.5 9.5 9.5 0 0 0-9.5-9.5zM12.565 7a1.226 1.226 0 1 1-1.226 1.226A1.224 1.224 0 0 1 12.565 7zm-6.13 0A1.226 1.226 0 1 1 5.21 8.224 1.224 1.224 0 0 1 6.435 7zm7.462 6.52a5.722 5.722 0 0 1-8.8 0 .613.613 0 0 1 .942-.785 4.5 4.5 0 0 0 6.91 0 .613.613 0 0 1 .951.783z"
                    transform="translate(0 -.563)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const TrainingIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#333";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
            >
                <path
                    data-name="Icon material-school"
                    d="M6.955 21.467v6.667L16.5 34.5l9.545-6.367v-6.666L16.5 27.833zM16.5 4.5l-15 10 15 10 12.273-8.183v11.516H31.5V14.5z"
                    transform="translate(-1.5 -4.5)"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const SaveIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#fff";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
            >
                <path
                    d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Z"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const StylusNoteIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#fff";
    return (
        <SvgIcon>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
            >
                <path
                    d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z"
                    fill={color}
                />
            </svg>
        </SvgIcon>
    );
};

export const CakeIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#fff";
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" 
                 fill={color}>
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 6c1.11 0 2-.9 2-2 0-.38-.1-.73-.29-1.03L12 0l-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm6 3h-5V7h-2v2H6c-1.66 0-3 1.34-3 3v9c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-9c0-1.66-1.34-3-3-3zm1 11H5v-3c.9-.01 1.76-.37 2.4-1.01l1.09-1.07 1.07 1.07c1.31 1.31 3.59 1.3 4.89 0l1.08-1.07 1.07 1.07c.64.64 1.5 1 2.4 1.01v3zm0-4.5c-.51-.01-.99-.2-1.35-.57l-2.13-2.13-2.14 2.13c-.74.74-2.03.74-2.77 0L8.48 12.8l-2.14 2.13c-.35.36-.83.56-1.34.57V12c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v3.5z" />
            </svg>
        </SvgIcon>
    );
};

export const RewardedAdsIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#fff";
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color}>
                <path d="M480-520q33 0 56.5-23.5T560-600q0-33-23.5-56.5T480-680q-33 0-56.5 23.5T400-600q0 33 23.5 56.5T480-520ZM280-120v-80h160v-124q-49-11-87.5-41.5T296-442q-75-9-125.5-65.5T120-640v-40q0-33 23.5-56.5T200-760h80v-80h400v80h80q33 0 56.5 23.5T840-680v40q0 76-50.5 132.5T664-442q-18 46-56.5 76.5T520-324v124h160v80H280Zm0-408v-152h-80v40q0 38 22 68.5t58 43.5Zm200 128q50 0 85-35t35-85v-240H360v240q0 50 35 85t85 35Zm200-128q36-13 58-43.5t22-68.5v-40h-80v152Zm-200-52Z"/>
            </svg>
        </SvgIcon>
    );
};

export const MopIcon: FC<ActionItemIcon> = ({ colorHex }) => {
    const color = colorHex ? colorHex : "#fff";
    return (
        <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color}>
                <path d="M440-520h80v-280q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800v280ZM200-360h560v-80H200v80Zm-58 240h98v-80q0-17 11.5-28.5T280-240q17 0 28.5 11.5T320-200v80h120v-80q0-17 11.5-28.5T480-240q17 0 28.5 11.5T520-200v80h120v-80q0-17 11.5-28.5T680-240q17 0 28.5 11.5T720-200v80h98l-40-160H182l-40 160Zm676 80H142q-39 0-63-31t-14-69l55-220v-80q0-33 23.5-56.5T200-520h160v-280q0-50 35-85t85-35q50 0 85 35t35 85v280h160q33 0 56.5 23.5T840-440v80l55 220q13 38-11.5 69T818-40Zm-58-400H200h560Zm-240-80h-80 80Z"/>
            </svg>
        </SvgIcon>
    );
};

